import logo from './logo.svg';
import punditLogo from './assets/punditlogo.png'; 
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { useDrag } from 'react-use-gesture';

<meta name="viewport" content="width=device-width, initial-scale=1"></meta>

const WelcomeMessage = ({ name, avatarColor }) => {
  if (!name || !avatarColor) return null;

  return (
    <div className="welcome-message">
      <p className="typewriter-text typewriter-text-1">
        Welcome <span className="avatar-circle" style={{ backgroundColor: avatarColor }}></span>{name} to this minimally<br></br> functional demo
        (designed for phones)
      </p>
      <p className="typewriter-text typewriter-text-2">
        Polling shows most (68%) Americans under 35 <br></br> don't trust the news media in general,
        and most <br></br> (54%) Americans overall distrust the news<br></br> sources they use themselves.
      </p>
      <p className="typewriter-text typewriter-text-3">
        This is a problem that's going to get worse: <br></br>articles are a less engaging medium than
        TikTok, <br></br>and there's a bigger flood of credible AI-generated<br></br> media coming.
      </p>
      <p className="typewriter-text typewriter-text-4">
        Building a trustworthy news product that can <br></br>keep users engaged and well-informed
        is really <br></br>important. I think there's an opportunity to make<br></br> news consumption more
        interactive / social /<br></br> engaging and I'd love to hear your thoughts.
      </p>
      <p className="typewriter-text typewriter-text-5">- Charlie (<a href="mailto:cplasswell@gmail.com" className="email-link">cplasswell@gmail.com</a>)</p>
    </div>
    
  );
};

const questions = [
    {
      id: 'q1',
      text: 'Q1: Following the New Hampshire primary, who will be the Republican nominee for president?',
      options: ['Donald Trump', 'Nikki Haley'],
      article: (
        <div>
          <h2>Trump Triumphs in New Hampshire GOP Primary; Haley Vows to Fight On</h2>
          <img 
  src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Former_President_Donald_Trump_Holds_Campaign_Rally_In_Rochester%2C_New_Hampshire_%2853481310366%29.jpg/1600px-Former_President_Donald_Trump_Holds_Campaign_Rally_In_Rochester%2C_New_Hampshire_%2853481310366%29.jpg?20240123123345" 
  alt="New Hampshire Primary" 
  style={{ 
    width: '80%', 
    display: 'block', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    maxWidth: '100%' // Ensures the image is responsive
  }} 
/>
<p>Donald Trump secured a commanding win in the New Hampshire Republican primary Tuesday, outpacing his only remaining rival, former South Carolina governor Nikki Haley, by 10.5 percentage points with 70% of votes counted. This success builds on his earlier win in the Iowa caucuses and signals a strong start to his campaign for a White House comeback.</p>
<p>The loss represents a setback for Haley, who committed to continuing her campaign at least through the South Carolina primary and the Super Tuesday contests. Despite her efforts to secure the support of independents historically overrepresented in New Hampshire, Trump secured the majority of votes, indicating an uphill battle ahead for Haley. Her strategy of appealing to voters tired of Trump's controversies and framing herself as a viable alternative to both Trump and President Joe Biden has yet to resonate with a majority of Republican voters.</p>
<p>The New Hampshire results also highlighted Trump's potential vulnerabilities in a general election, particularly among independent voters, a crucial demographic in swing states. Despite this, Trump has garnered significant support within the Republican Party, with endorsements from key figures like Senator Tim Scott and former Florida Governor Ron DeSantis, consolidating his position as the party's frontrunner.</p>
<p>On the Democratic side, President Biden, running as a write-in candidate, won the New Hampshire primary over Rep. Dean Phillips. This victory, though largely symbolic, sets the stage for a potential Biden-Trump rematch in the general election.</p>
<p>Haley's persistence in the race reflects her belief in her electability, a stance supported by some polls showing her as a stronger general-election candidate against Biden compared to the former president. Trump labled Haley an "impostor" following her concession speech, warning of political consequences for her deicison, and has set his sights on the general election with significant party backing. While many commentators view Trump's coronation as the nominee as a foregone conclusion, Haley maintains a considerable war chest and will hope to consolidate support among moderate voters in upcoming primaries.</p>
<div style={{ height: '175px' }}></div>
        </div>
      )
    },

    {
      id: 'q2',
      text: 'Q2: If a sitting president ordered the assassination of a political rival, should they be immune from criminal prosecution?',
      options: ['No', 'No, but they must be convicted by the Senate first', 'Yes'],
      article: (
        <div>
          <h2>Judge Presses Trump Lawyers on Limits of Presidential Immunity</h2>
          <img 
  src="https://upload.wikimedia.org/wikipedia/commons/0/03/District_of_Columbia_Court_of_Appeals.JPG" 
  alt="US Court of Appeals " 
  style={{ 
    width: '70%', 
    display: 'block', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    maxWidth: '100%' // Ensures the image is responsive
  }} 
/>
          <p>In a hearing in the U.S. Court of Appeals for the D.C. Circuit last week, Judge Florence Pan posed a hypothetical scenario to former president Donald Trump’s legal team to explore the limits, if any, to the legal protections they contend the office afforded their client. “Could a president order Seal Team Six to assassinate a political rival?”</p>
          <p>While the former president’s lawyer D. John Sauer was quick to contend that a president “would speedily be” impeached in such a scenario, Pan pushed further: “I asked you a yes-or-no question…would he be subjected to criminal prosecution?”
</p>
          <p>“If he were impeached and convicted first,” Sauer replied.</p>
          <p>Presidents have made ever-more expansive interpretations of the powers of the office over the years—from Lincoln’s suspension of habeas corpus during the Civil War to FDR’s use of executive orders. The doctrine at issue might be best summarized by Nixon’s famous quote when interviewed by David Frost that “when the president does it, it’s not illegal.” </p>
          <p>Trump’s defense has recemtly taken a more Nixon-ish tone: whether his involvement with the capitol riots of January 6th was a good-faith effort to “stop the steal” is not ultimately significant, the defense has argued, because a political process of removal would be a precondition for any criminal accountability. Sauer was quick to position Trump’s claim to immunity alongside his predecessor’s transgressions—could a prosecutor go after George W. Bush for false information in the lead-up to the invasion of Iraq, or pursue Barack Obama for killing American citizen Anwar al-Awlaki without a trial in 2011?</p>
          <p>Pan’s hypothetical places expansive executive power in direct conflict with democracy’s functioning, and Justice Department lawyer James Pearce was unmoved by Sauer’s thinking, asking the judges “What kind of world are we living in…if a president orders his S.E.A.L. team to murder a political rival and then resigns or is not impeached, and that’s not a crime? I think that is an extraordinarily frightening future that should weigh heavily on the court’s decision.”
</p>
          <p>Following the hearing, Trump addressed reporters outside the courtroom. “When they talk about [a] threat to democracy, that is your real threat to democracy. I feel that as a president, you have to have immunity, very simple.”</p>
          <p>Democratic Senator Brian Schatz said the arguments were a grave warning on the implications of a second Trump administration.  "I do not think that the American public and even the American political community is sufficiently alarmed…when the official position of Trump's lawyers is going to be that he could order a murder of his political rivals using Seal Team Six and that would be up to the legislature to determine whether or not it was criminal."</p>
          <p>Speaking on MSNBC, Schatz continued, "It's a new Rubicon we've passed and I think the American public has to wake up to the fact that look, it's very exhausting to follow Trump, certainly if you're not a political person...but American democracy is at stake and this is a new turn for him where he's taking the official position that he gets to do whatever he wants.”</p>
          <div style={{ height: '175px' }}></div>
        </div>
      )
    },
    {
      id: 'q3',
      text: 'Q3: Minimum wages have increased in 22 states this year, but the federal rate ($7.25) has not changed since 2009. What is the ideal federal minimum wage?',
      options: ['Donald Trump'],
      article: (
        <div>
          <p><i>Australia has the highest national hourly wage at ~15 USD, while DC's is the highest in the country at $17</i>.</p>
          <img 
  src="/wages.png" 
  alt="Minimum Wages" 
  style={{ 
    width: '95%', 
    display: 'block', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    maxWidth: '100%' // Ensures the image is responsive
  }} 
/> 
          <p>On January 1, 22 states increased their minimum wages, raising pay for an estimated 9.9 million workers. In total, workers will receive $6.95 billion in additional wages from state minimum wage increases. In addition, 38 cities and counties will increase their minimum wages on January 1 above their state’s wage floors, adding to the number of workers likely to see increased earnings.</p>
          <p><i><a href="https://www.epi.org/blog/twenty-two-states-will-increase-their-minimum-wages-on-january-1-raising-pay-for-nearly-10-million-workers/" target="_blank">via Economic Policy Institute</a></i></p><div style={{ height: '175px' }}></div>
        </div>
      )
    },

    {
      id: 'q4',
      text: 'Q4: Last week, right-wing Dutch politician Geert Wilders drew comparisons to Jar-Jar Binks for a tweet. How do you say "We have a serious problem" in Dutch?',
      options: ['Donald Trump'],
      article: (
        <div>
          <img 
  src="https://i.imgur.com/8j8iy8M.jpg" 
  alt="Dutch Tweet" 
  style={{ 
    width: '100%', 
    display: 'block', 
    marginLeft: 'auto', 
    marginRight: 'auto', 
    maxWidth: '100%' // Ensures the image is responsive
  }} 
/>
        </div>
      )
    },


    {
      id: 'q5',
      text: ' ',
      options: [' '],
      article: (
        <div>
        </div>
      )
    },
];

const articleRoot1 = document.createElement('div');
const articleRoot2 = document.createElement('div'); 
const articleRoot3 = document.createElement('div');


document.body.appendChild(articleRoot1); 
document.body.appendChild(articleRoot2);
document.body.appendChild(articleRoot3);



function App() {
  
  const appStyles = {
    backgroundColor: '#C91D1D',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const bannerStyles = {
    fontFamily: 'Arial Black',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '36px',
    color: 'white',
    padding: '10px 40px',
    backgroundColor: 'transparent',
    borderBottom: '1px solid white',
    position: 'fixed',
    top: '0',
    width: '100%',
    textAlign: 'left',
  };

  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '300px',
    width: '100%',
  };

  const inputStyles = {
    margin: '10px 0',
    padding: '10px',
    width: '90%',
    borderRadius: '5px',
    border: '1px solid #ccc',
  };

  const articleStyles = {
    position: 'fixed',
    width: '100%',
    height: '100%', // Adjusted height if needed
    backgroundColor: 'white',
    zIndex: 1000,
    borderRadius: '20px 20px 0 0',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
    touchAction: 'none',
    bottom: '0',
  };

  const extendedTexts = {
    "No": "Brian Schatz (D-HI): 'I do not think the American political community is sufficiently alarmed...It's a new Rubicon we've passed; American democracy is at stake.'",
    "No, but they must be convicted by the Senate first": "Trump lawyer D. John Sauer: 'He would have to be impeached and convicted before the criminal prosecution...That's not a frightening future. That's our republic.'",
    "Yes": "Donald Trump: 'WITHOUT IMMUNITY, IT WOULD BE VERY HARD FOR A PRESIDENT TO ENJOY HIS OR HER “GOLDEN YEARS” OF RETIREMENT. THEY WOULD BE UNDER SIEGE BY RADICAL, OUT OF CONTROL PROSECUTORS'"
  };  
  

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [referrerData, setReferrerData] = useState(null);
  const [avatarColor, setAvatarColor] = useState('');
  const [showContinue, setShowContinue] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);
  const [isAnimationComplete, setIsAnimationComplete] = useState(false);
  const [articleAnimation, setArticleAnimation] = useState(false);
  const [isArticleOpen, setIsArticleOpen] = useState(false);
  const questionContainerRef = useRef(null);
  const [userId, setUserId] = useState(null);
  const [referrerAnswer, setReferrerAnswer] = useState(null);
  const [referrerName, setReferrerName] = useState('');
 const [referrerAvatarColor, setReferrerAvatarColor] = useState('');
   const [showNextQuestionButton, setShowNextQuestionButton] = useState(false);
   const [referrerAnswers, setReferrerAnswers] = useState({});
   const [isSecondQuestionAnswered, setIsSecondQuestionAnswered] = useState(false);
   const [showReferrerSliderInfo, setShowReferrerSliderInfo] = useState(false);
   const [showSliderSubmitButton, setShowSliderSubmitButton] = useState(true);
   const [collectedAnswers, setCollectedAnswers] = useState({});
   const [showShareBox, setShowShareBox] = useState(false);
   const [showDelayText, setShowDelayText] = useState(false);
   const [showSubmitButtonQ4, setShowSubmitButtonQ4] = useState(true);



   const [sliderValue, setSliderValue] = useState(7.25);

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  

  const handleAnimationEnd = () => {
    setIsAnimationComplete(true);
  };

  const stringToBrightColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
    for (let i = 0; i < 3; i++) {
      // Ensure higher values for RGB components
      const value = Math.floor((hash >> (i * 8)) & 0xFF);
      const brightValue = Math.min(value + 100, 255); // Add 100 to the value but cap at 255
      color += ('00' + brightValue.toString(16)).substr(-2);
    }
  
    return color;
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formVisible, setFormVisible] = useState(true); 


  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showArticleContent, setShowArticleContent] = useState(false);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showArticle, setShowArticle] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');


  const articleStyle = useSpring({
    bottom: showArticle ? '0%' : '-90%',
    opacity: articleAnimation ? 1 : 0,
    config: { friction: 26 }
  });


  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
  
    return color;
  };


  const formAnimation = useSpring({
    opacity: isSubmitted ? 0 : 1,
    config: { duration: 500 },
    onRest: () => {
      if (isSubmitted) {
        setIsAnimationComplete(true); // Set the state when the animation completes
      }
    },
  });

  

  const isNameValid = (name) => {
    return /^[A-Za-z\s]{1,50}$/.test(name); // Allow letters and spaces, up to 50 characters
  };

  const isEmailValid = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Submit button clicked');
  
    if (!isNameValid(name)) {
      alert('Please enter a valid name (letters only, up to 50 characters).');
      return;
    }
  
    if (!isEmailValid(email)) {
      alert('Please enter a valid email address.');
      return;
    }
  
    const avatarColor = stringToBrightColor(name);
    setAvatarColor(avatarColor);
  
    try {
      const response = await fetch('/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, avatarColor }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Success:', data);
        setIsSubmitted(true);
        setUserId(data._id);
        console.log('isSubmitted set to true');
        window.scrollTo(0, 0);
        setTimeout(() => {
          setFormVisible(false); // Hide the form
          setShowContinue(true); 
        }, 9500);
        setUserId(data._id); // Save the user's ID in the state
        setIsSubmitted(true);
        

        
      } else {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const [showQuestion, setShowQuestion] = useState(false);

  const fadeOutAnimation = useSpring({
    opacity: showWelcomeMessage ? 1 : 0,
    config: { duration: 500 },
  });

  const fadeInAnimation = useSpring({
    opacity: showQuestion ? 1 : 0,
    from: { opacity: 0 },
    config: { duration: 500 },
  });

  const fetchReferrerDataFromURL = async () => {
    const params = new URLSearchParams(window.location.search);
    const referrerId = params.get('ref') || '0';
  
    try {
      const response = await fetch(`/api/users/${referrerId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
  
      // Set states with the fetched data
      setReferrerData(data); // Store all referrer data
      setReferrerAnswers(data.answers); // Store referrer's answers
      setReferrerName(data.name); // Store the referrer's name
      setReferrerAvatarColor(data.avatarColor); // Store the referrer's avatar color
      console.log('Referrer Data:', data);
    } catch (error) {
      console.error('Error fetching referrer data:', error);
    }
  };
  

  const welcomeAnimation = useSpring({
    opacity: showWelcomeMessage ? 1 : 0,
    config: { duration: 500 },
    onRest: () => {
      if (!showWelcomeMessage) {
        setIsAnimationComplete(true); // Set true when animation completes
      }
    },
  });


  const handleAnswerSelected = (questionId, answer) => {
    setSelectedAnswer(answer);
  
    // Construct the updated answers
    const updatedAnswers = { ...collectedAnswers, [questionId]: answer };
    console.log('Current answers:', updatedAnswers);
  
    // Update the state with the new answers
    setCollectedAnswers(updatedAnswers);
  
    // Check if it's the last question
    if (questionId === 'q4') {
      handleFinish(updatedAnswers); // Send the updated answers directly
    } else {
      // Proceed to the next question
      setShowNextQuestionButton(true);
  
      if (currentQuestionIndex === 1) {
        setIsSecondQuestionAnswered(true);
      }
    }
  };
  
  

  const handleFinish = async (updatedAnswers) => {
    if (!userId) {
      console.error('User ID is not set');
      return;
    }
  
    // Display processing text or animation
    setShowDelayText(true);
  
    // Wait for 5 seconds before proceeding
    setTimeout(async () => {
      try {
        const response = await fetch(`/api/users/${userId}/answers`, {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ answers: updatedAnswers }),
        });
  
        if (response.ok) {
          console.log("Submitting answers:", updatedAnswers);
  
          // Hide the next question button and the article component
          setShowQuestion(false);
          setShowShareBox(true);
          setShowDelayText(false); // Hide processing text or animation
  
          // Additional actions after successful submission can be added here
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      } catch (error) {
        console.error('Error submitting all answers:', error);
      }
    }, 10000); // 5000 milliseconds = 5 seconds
  };
  
  const copyToClipboard = () => {
    const referrerLink = `${window.location.origin}/?ref=${userId}`;
    navigator.clipboard.writeText(referrerLink).then(() => {
      setCopySuccess("Link copied!");
      setTimeout(() => setCopySuccess(''), 2000); // Message disappears after 2 seconds
    }, (err) => {
      console.error('Could not copy text: ', err);
      setCopySuccess("Failed to copy");
    });
  }
  
  
  const questionOrder = ['q1', 'q2', 'q3', 'q4', 'q5'];
  const [currentQuestionId, setCurrentQuestionId] = useState(questionOrder[0]);
  const referrerCurrentAnswer = referrerAnswers[`q${currentQuestionIndex + 1}`];
  
  const currentQuestion = questions.find(q => q.id === currentQuestionId);
  
  const handleNextQuestion = () => {
    const currentIdx = questionOrder.indexOf(currentQuestionId);
    const nextQuestionId = questionOrder[currentIdx + 1];
  
    if (nextQuestionId) {
      setCurrentQuestionId(nextQuestionId);
      setCurrentQuestionIndex(currentIdx + 1); // Update the question index
      setSelectedAnswer(null); // Clear the selected answer for the new question
      setShowNextQuestionButton(false); // Hide the 'Next Question' button
      setIsSecondQuestionAnswered(false); // Reset the second question answered flag
      setShowReferrerSliderInfo(false);
      console.log('Moving on to next question...');
    } else {
      console.log('No more questions');
      // Handle end of questions, like showing a summary or closing the quiz
    }
  };
  
// Define a new animation for the "next question" button
const nextQuestionAnimation = useSpring({
  opacity: showNextQuestionButton ? 1 : 0,
  config: { duration: 500 },
});

  const toggleArticleView = () => {
    setShowArticle(!showArticle);
  };


useEffect(() => {
  const fetchReferrerData = async () => {
    const params = new URLSearchParams(window.location.search);
    const referrerId = params.get('ref');

    if (!referrerId) {
      // Handle the case where no referrerId is present
      console.log('No referrer ID present');
      return;
    }

    try {
      const response = await fetch(`/api/users/${referrerId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setReferrerData(data);
      console.log('Referrer Data:', data);
    } catch (error) {
      console.error('Error fetching referrer data:', error);
    }
  };

  fetchReferrerData();
}, []);

const [articlePosition, setArticlePosition] = useState(0); // Initial state

useEffect(() => {
  // Set the initial position of the article card
  const initialPosition = 75; // This means 90% of the viewport height
  setArticlePosition(initialPosition);
}, []);

useEffect(() => {
  const questionContainer = questionContainerRef.current;
  const preventScroll = (e) => e.preventDefault();

  if (questionContainer && !showArticle) {
      questionContainer.addEventListener('touchmove', preventScroll, { passive: false });
  }

  return () => {
      if (questionContainer) {
          questionContainer.removeEventListener('touchmove', preventScroll, { passive: false });
      }
  };
}, [showArticle]);

useEffect(() => {
  if (isArticleOpen) {
    // Enable scrolling when article is open
    document.body.style.overflow = 'auto';
  } else {
    // Disable scrolling when article is not open
    document.body.style.overflow = 'hidden';
  }

  // Cleanup function to reset overflow when component unmounts
  return () => {
    document.body.style.overflow = 'auto';
  };
}, [isArticleOpen]);

const articleSpring = useSpring({
  top: `${articlePosition}vh`,
  config: { friction: 26 }
});

const handleTap = () => {
  setIsArticleOpen(!isArticleOpen);
  setArticlePosition(articlePosition === 75 ? 10 : 75);
};



const handleContinueClick = () => {
  setShowWelcomeMessage(false);
  setShowContinue(false);
  console.log('Current State - Name:', name, 'Avatar Color:', avatarColor, 'Referrer Name:', referrerName, 'Referrer Avatar Color:', referrerAvatarColor);
  fetchReferrerDataFromURL(); // Fetch referrer data here
  setTimeout(() => {
    setShowQuestion(true);
    setArticlePosition(75); // Reset article position to initial retracted state
  }, 500);
};

const shareBoxAnimation = useSpring({
  opacity: showShareBox ? 1 : 0,
  transform: showShareBox ? `translate(-50%, -50%)` : `translate(-50%, -60%)`,
});


return (
  <div style={appStyles}>
    <div style={bannerStyles}>
    <img src={punditLogo} alt="Pundit Logo" className="logoImage" />
    </div>
      {formVisible && (
        <animated.form style={{ ...formStyles, ...formAnimation }} onSubmit={handleSubmit}>
          <input 
            type="text" 
            placeholder="First name" 
            style={inputStyles}
            value={name}
            onChange={handleNameChange}
            disabled={isSubmitted} // Disable input after submission
          />
          <input 
            type="email" 
            placeholder="Email" 
            style={inputStyles}
            value={email}
            onChange={handleEmailChange}
            disabled={isSubmitted} // Disable input after submission
          />
          <button type="submit" style={{...inputStyles, backgroundColor: '#C91D1D', color: 'white'}}>
            {isSubmitted ? '✓' : 'Submit'}
          </button>
        </animated.form>
        

        
      )}
      <animated.div style={welcomeAnimation}>
        {showWelcomeMessage && (
          <div className="welcome-message-container">
            <WelcomeMessage name={name} avatarColor={avatarColor} />
          </div>
        )}
      </animated.div>

      {/* Conditionally render the Continue button */}
      {!formVisible && showContinue && (
        <div className="continue-button-container" onClick={handleContinueClick}>
          <div className="continue-text">Continue</div>
        </div>
      )}

<animated.div style={fadeInAnimation}>
  {showQuestion && (
    <>
      <div className="question-container" ref={questionContainerRef}>
        {currentQuestionIndex !== 1 || !isSecondQuestionAnswered ? (
          <p className="question-text">{questions[currentQuestionIndex].text}</p>
        ) : null}

      {currentQuestionIndex === 2 ? (
        <>
          <p className="slider-value">${sliderValue}</p>
          <input
            className="slider-input"
            type="range"
            min="0"
            max="20"
            step="0.01"
            value={sliderValue}
            onChange={handleSliderChange}
          />
          {showSliderSubmitButton && (
            <button
              className="slider-submit-button"
              onClick={() => {
                handleAnswerSelected('q3', sliderValue.toString());
                setIsArticleOpen(false); // Hides the article content
                setShowArticle(false);  // Hides the entire article component
                setShowReferrerSliderInfo(true);
                setShowSliderSubmitButton(false);
              }}
            >
              Submit
            </button>
          )}
        </>
      ) : null}


{currentQuestionIndex === 3 ? (
  <div>
    <input
      type="text"
      className="question-input"
      placeholder="Your answer..."
      maxLength={70}
      onChange={(e) => setSelectedAnswer(e.target.value)}
    />
    {showDelayText && (
      <div>
        <div className="correct-answer-info">Correct: We hebben een serieus probleem</div>
        {referrerData && (
          <div className="referrer-info">
            <span className="avatar-circle" style={{ backgroundColor: referrerAvatarColor }}></span>
            <span className="user-name">{referrerName}</span>
            <span>: {referrerAnswers['q4']}</span>
          </div>
        )}
      </div>
    )}
    {showSubmitButtonQ4 && (
      <button
        className="slider-submit-button"
        onClick={() => {
          handleAnswerSelected('q4', selectedAnswer);
          setShowSubmitButtonQ4(false); // Immediately hide the button on click
          setShowNextQuestionButton(false);
          setShowSliderSubmitButton(false);
          setShowArticle(false);
          setShowDelayText(true); 
          setTimeout(() => {
            setShowDelayText(false);
            handleFinish();
          }, 14000); // 10 seconds delay
        }}
      >
        Submit
      </button>
    )}
  </div>
) : null}

        {currentQuestionIndex !== 2 && currentQuestionIndex !== 3 &&
        questions[currentQuestionIndex].options.map((option, index) => (
          <div key={index} className="answer-container">
          {currentQuestionIndex === 1 && selectedAnswer && (
            <img src={`/avatar${index + 1}.jpeg`} alt={`Avatar ${index + 1}`} className="avatar-image-outside" />
          )}
          <button
            className={`answer-button ${
              selectedAnswer === option ? 'selected-answer' : 
              (selectedAnswer && referrerCurrentAnswer === option && referrerCurrentAnswer !== selectedAnswer) ? 'referrer-answer-different' : 
              (referrerCurrentAnswer === option) ? 'referrer-answer' : ''
            }`}
            onClick={() => handleAnswerSelected('q' + (currentQuestionIndex + 1), option)}
      disabled={!!selectedAnswer}
          >
            {currentQuestionIndex === 1 && selectedAnswer ? (
              <span className="extended-answer-text"> {extendedTexts[option]} </span>
            ) : (
              option
            )}
          </button>
          {selectedAnswer === option && (
            <div className="user-info">
              <span className="avatar-circle" style={{ backgroundColor: avatarColor }}></span>
              <span className="user-name">{name}</span>
            </div>
          )}
          {selectedAnswer && referrerCurrentAnswer === option && (
            <div className="referrer-info">
              <span className="avatar-circle" style={{ backgroundColor: referrerAvatarColor }}></span>
              <span className="user-name">{referrerName}</span>
              </div>
            )}
          </div>
        ))
      }
      </div>
    </>
  )}
</animated.div>


      {showQuestion && (
  <animated.div style={{ ...articleSpring, ...articleStyles, position: 'absolute' }}>
    <div className="article-content" style={{ overflowY: isArticleOpen ? 'auto' : 'hidden', maxHeight: '90vh' }}>
      <div className="tap-to-read" onClick={handleTap}>
        <span className={`article-arrow ${!isArticleOpen ? 'article-arrow-down' : ''}`}>&#9660;</span>
        Tap to read
      </div>
      {questions[currentQuestionIndex].article}
    </div>
  </animated.div>
)}

{showReferrerSliderInfo && (
  <div className="referrer-slider-info">
    <span className="avatar-circle" style={{ backgroundColor: referrerAvatarColor }}></span>
    <span className="user-name">{referrerName}</span>
    <span>: ${referrerAnswers[`q${currentQuestionIndex + 1}`]}</span>
  </div>
)}

{
  showNextQuestionButton && (
    <animated.div style={nextQuestionAnimation}>
      <button className="next-question-button" onClick={handleNextQuestion}>
        Next Question
      </button>
    </animated.div>
  )
}

{showShareBox && (
  <animated.div style={shareBoxAnimation} className="share-box">
    <h3>Finished! Click to copy a link & share your answers:</h3>
    <input 
      type="text" 
      value={`${window.location.origin}/?ref=${userId}`} 
      readOnly
      onClick={copyToClipboard} 
    />
    {copySuccess && <div>{copySuccess}</div>}
    <a href="mailto:cplasswell@gmail.com">Contact</a>
  </animated.div>
)}



    </div>
  );
}


export default App;